import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import { Section } from '../components/sections'
import { Grid } from '../components/grid'
import { Button } from '../components/button'

import styled from 'styled-components'
import { colors } from '../styles/colors'
import { ContactForm } from '../components/form'

const ContactWrapper = styled.div`
  margin-top: 60px;
`

const ContactList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  font-family: 'Founders Grotesk Mono';

  li:not(:last-of-type) {
    margin-bottom: 10px;
  }
`

const Link = styled.a`
  text-decoration: none;
  color: ${colors.primary.default};
  transition: color 0.25s ease-in-out;

  &:hover {
    color: ${colors.quaternary.default};
    transition: color 0.25s ease-in-out;
  }
`

const ContactHeading = styled.p`
  color: ${colors.quaternary.default};
`

const Address = styled.div`
  font-family: 'Founders Grotesk Mono';
`

const ContactPage = ({ data, props }) => (
  <StaticQuery
    query={graphql`
      query ContactPageQuery {
        markdownRemark(frontmatter: { path: { eq: "/contact" } }) {
          excerpt
          frontmatter {
            title
            facebook
            instagram
            dribbble
            phone
            email
            address
            seoTitle
            seoDescription
            seoImage
          }
        }
        contact: markdownRemark(frontmatter: { type: { eq: "contactInfo" } }) {
          frontmatter {
            phoneNumber
            address1
            city
            state
            zipCode
            directionsLink
            email
          }
        }
        socialMedia: markdownRemark(
          frontmatter: { type: { eq: "socialMedia" } }
        ) {
          frontmatter {
            dribbble
            facebook
            instagram
          }
        }
        seo: file(
          relativePath: { eq: "seo.md" }
          sourceInstanceName: { eq: "data" }
        ) {
          data: childMarkdownRemark {
            frontmatter {
              image
              description
              siteTitle
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[
            {
              name: 'description',
              content:
                data.markdownRemark.frontmatter.seoDescription ||
                data.markdownRemark.excerpt ||
                data.seo.data.frontmatter.description,
            },
            {
              name: 'og:image',
              content:
                'https://madebypioneer.com' +
                (data.markdownRemark.frontmatter.seoImage ||
                  data.seo.data.frontmatter.image),
            },
          ]}
          title={`${data.markdownRemark.frontmatter.seoTitle ||
            data.markdownRemark.frontmatter.title} | ${
            data.seo.data.frontmatter.siteTitle
          }`}
        />
        <Section>
          <Grid offset={3}>
            <ContactForm />
          </Grid>
          <ContactWrapper>
            <Grid
              tablet={{ columns: 'repeat(3, 1fr)' }}
              desktop={{ columns: 'repeat(3, 1fr)' }}
              offset={3}
            >
              {/* Follow Us */}
              {data.socialMedia && (
                <div>
                  <ContactHeading className="blockquote">
                    Follow Us
                  </ContactHeading>
                  <ContactList>
                    {data.socialMedia.frontmatter.instagram && (
                      <li>
                        <a
                          href={`https://instagram.com/${data.socialMedia.frontmatter.instagram}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button>on Instagram</Button>
                        </a>
                      </li>
                    )}
                    {data.socialMedia.frontmatter.facebook && (
                      <li>
                        <a
                          href={`https://facebook.com/${data.socialMedia.frontmatter.facebook}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button>on Facebook</Button>
                        </a>
                      </li>
                    )}
                    {data.socialMedia.frontmatter.dribbble && (
                      <li>
                        <Link
                          href={`https://dribbble.com/${data.socialMedia.frontmatter.dribbble}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button>on Dribbble</Button>
                        </Link>
                      </li>
                    )}
                  </ContactList>
                </div>
              )}
              {/* Say Hay */}
              {data.contact && (
                <div>
                  <ContactHeading className="blockquote">
                    Say Hey
                  </ContactHeading>
                  <ContactList>
                    {data.contact.frontmatter.phoneNumber && (
                      <li>
                        <a
                          href={`tel:${data.contact.frontmatter.phoneNumber.replace(
                            /"/g,
                            ''
                          )}`}
                        >
                          <Button>on the phone</Button>
                        </a>
                      </li>
                    )}
                    {data.contact.frontmatter.email && (
                      <li>
                        <a href={`mailto:${data.contact.frontmatter.email}`}>
                          <Button>on an email</Button>
                        </a>
                      </li>
                    )}
                  </ContactList>
                </div>
              )}

              {/* Stop By */}
              {data.contact && (
                <Address>
                  <ContactHeading className="blockquote">
                    Stop By
                  </ContactHeading>
                  <p>
                    {data.contact.frontmatter.address1}
                    <br />
                    {data.contact.frontmatter.city},{' '}
                    {data.contact.frontmatter.state}{' '}
                    {data.contact.frontmatter.zipCode}
                  </p>
                  <p>
                    <a
                      href={data.contact.frontmatter.directionsLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button>Get Directions</Button>
                    </a>
                  </p>
                </Address>
              )}
            </Grid>
          </ContactWrapper>
        </Section>
      </Layout>
    )}
  />
)

export default ContactPage
